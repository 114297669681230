import { User } from '@securecore-new-application/securecore-datacore/lib/types';

export const headers = [
  'Username',
  'First Name',
  'Last Name',
  'Title',
  'Email',
  'Company',
  'Property',
  'Role',
  'Logged in',
];

const convertRowToCSV = (row: User): string => {
  const user = [
    row.userName,
    row.profile?.firstName,
    row.profile?.lastName,
    row.title,
    row.profile?.email,
    row.company?.name || '',
    row.teamMembers[0]?.property?.name || '[All Properties]',
    row.teamMembers[0]?.role || row.role,
    row.pendingInvitation ? '' : 'Logged In',
  ];

  return user.map((value) => `"${(value || '').replace(/"/g, '""')}"`).join(',');
};

const parseUserAccess = (user: User) => {
  if (user.teamMembers.length) {
    return user.teamMembers.map((teamMember) => ({
      ...user,
      teamMembers: [teamMember],
    }));
  }

  return user;
};

const convertToCSV = (data: User[]): string => {
  const rows = data.flatMap(parseUserAccess);

  return `${headers.join(',')}\n${rows.map(convertRowToCSV).join('\n')}`;
};

const createDownloadableLink = (csv: string, filename: string) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setTimeout(() => URL.revokeObjectURL(url), 100);
};

export const sendCSV = (filter: Record<string, string>, userList: User[] = []) => {
  if (userList.length) {
    const filename = [Object.keys(filter).join(''), Object.values(filter).join(''), 'users.csv']
      .filter(Boolean)
      .join('_');

    const csv = convertToCSV(userList);

    createDownloadableLink(csv, filename);
  }
};
